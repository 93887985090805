<template>
  <div class="cs-page">
    <CommonHeader />
    <div class="main-body">
      <slot></slot>
    </div>
    <CommonFooter />
    <CustomerService />
  </div>
</template>
<script>
export default {
  components: {
    CommonHeader: () => import("@/components/layout/CommonHeader"),
    CommonFooter: () => import("@/components/layout/CommonFooter"),
    CustomerService: () => import("@/components/layout/CustomerService"),
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.cs-page {
  padding-top: 40px;
  min-height: 100vh;
  .main-body {
    min-height: calc(100vh - 121px);
  }
}
</style>